var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('Pane'),_c('a-card',{staticClass:"container"},[_c('a-form',{attrs:{"label-col":{ span: 6 },"wrapper-col":{ span: 14 },"colon":false,"form":_vm.form}},[_c('a-row',[_c('a-col',{attrs:{"lg":12,"md":12,"sm":24}},[_c('a-form-item',{attrs:{"label":"项目名称"}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
                            'name',
                            {
                                initialValue: _vm.detail.name,
                                rules: [
                                    { required: true, message: '请输入！' },
                                ],
                            },
                        ]),expression:"[\n                            'name',\n                            {\n                                initialValue: detail.name,\n                                rules: [\n                                    { required: true, message: '请输入！' },\n                                ],\n                            },\n                        ]"}],staticStyle:{"width":"200px"},attrs:{"placeholder":"请输入"}})],1)],1),_c('a-col',{attrs:{"lg":12,"md":12,"sm":24}},[_c('a-form-item',{attrs:{"label":"开标日期"}},[_c('a-date-picker',{directives:[{name:"decorator",rawName:"v-decorator",value:([
                            'date',
                            {
                                initialValue: _vm.detail.date,
                                rules: [
                                    { required: true, message: '请输入！' },
                                ],
                            },
                        ]),expression:"[\n                            'date',\n                            {\n                                initialValue: detail.date,\n                                rules: [\n                                    { required: true, message: '请输入！' },\n                                ],\n                            },\n                        ]"}],staticStyle:{"width":"200px"}})],1)],1),_c('a-col',{attrs:{"lg":12,"md":12,"sm":24}},[_c('a-form-item',{attrs:{"label":"中标情况"}},[_c('a-select',{directives:[{name:"decorator",rawName:"v-decorator",value:([
                                'status',
                                {
                                    initialValue: _vm.detail.status,
                                }
                            ]),expression:"[\n                                'status',\n                                {\n                                    initialValue: detail.status,\n                                }\n                            ]"}],staticStyle:{"width":"200px"},attrs:{"placeholder":"请输入"}},_vm._l((_vm.status),function(item){return _c('a-select-option',{key:item.value,attrs:{"value":item.value}},[_vm._v(_vm._s(item.name))])}),1)],1)],1),_c('a-col',{attrs:{"lg":12,"md":12,"sm":24}},[_c('a-form-item',{attrs:{"label":"中标金额(万元)"}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
                            'amount',
                            {
                                initialValue: _vm.detail.amount,
                            }
                        ]),expression:"[\n                            'amount',\n                            {\n                                initialValue: detail.amount,\n                            }\n                        ]"}],staticStyle:{"width":"200px"},attrs:{"type":"number","placeholder":"请输入"}})],1)],1),_c('a-col',{attrs:{"lg":12,"md":12,"sm":24}},[_c('a-form-item',{attrs:{"label":"未中标原因"}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
                            'remark',
                            {
                                initialValue: _vm.detail.remark,
                            }
                        ]),expression:"[\n                            'remark',\n                            {\n                                initialValue: detail.remark,\n                            }\n                        ]"}],staticStyle:{"width":"200px"},attrs:{"placeholder":"请输入"}})],1)],1),_c('a-col',{attrs:{"lg":12,"md":12,"sm":24}},[_c('a-form-item',{attrs:{"label":"生产部门"}},[_c('a-select',{directives:[{name:"decorator",rawName:"v-decorator",value:([
                                'deptId',
                                {
                                    initialValue: _vm.detail.deptId,
                                    rules: [
                                        { required: true, message: '请输入！' },
                                    ],
                                }
                            ]),expression:"[\n                                'deptId',\n                                {\n                                    initialValue: detail.deptId,\n                                    rules: [\n                                        { required: true, message: '请输入！' },\n                                    ],\n                                }\n                            ]"}],staticStyle:{"width":"200px"},attrs:{"placeholder":"请输入","show-search":"","filter-option":_vm.$selectFilterOption}},_vm._l((_vm.organizationList),function(item){return _c('a-select-option',{key:item.id,attrs:{"value":item.id}},[_vm._v(_vm._s(item.uniqueName))])}),1)],1)],1)],1),_c('div',{staticClass:"center"},[_c('a-space',[_c('a-button',{on:{"click":function($event){return _vm.$close(_vm.$route.path)}}},[_vm._v("关闭")]),_c('a-button',{attrs:{"type":"primary","loading":_vm.loading},on:{"click":_vm.handleSubmit}},[_vm._v("保存")])],1)],1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }